<template>
  <div id="main-video-wrapper">
    <vue-plyr ref="plyr" loading="eager" :options="this.options">
      <video
        id="player"
        muted
        playsinline
        autoplay
        preload="metadata"
        :data-poster="introPic.src">
        <source src="@/assets/video/intro.mp4" type="video/mp4" />
        <source src="@/assets/video/intro.webm" type="video/webm" />
      </video>
    </vue-plyr>
    <div id="gradient"></div>
  </div>
</template>
<script>
import { gsap } from 'gsap'
export default {
  data() {
    return {
      introPic: require('@/assets/video/intro.jpg'),
      // Plyr options for main video
      options: {
        captions: {
          active: false,
        },
        autopause: false,
        autoplay: true,
        loop: {
          active: true,
        },
        muted: true,
        debug: false,
        blankVideo: require('@/assets/video/blank.mp4'),
        clickToPlay: false,
        fullscreen: {
          enabled: false,
          iosNative: false,
        },
        events: ['playing', 'pause', 'ready'],
        displayDuration: false,
        volume: 0,
        quality: {
          default: 1080,
          forced: false,
          options: [4320, 2880, 2160, 1440, 1080, 720, 576, 480, 360, 240],
        },
        controls: [],
        disableContextMenu: true,
        r: 0,
      },
              firstTimeVideoPlayed: true,
    }
  },
  methods: {
    figureMask() {
      // find the minimum radius needed to cover
      // the entire SVG and center the circle
      var data = document.querySelector('#goAway').getBBox()
      this.r = this.figureRadius(data.width, data.height)
    },

    figureRadius(w, h) {
      return Math.sqrt(Math.pow(w, 2) + Math.pow(h, 2)) / 2
    },
  },
  mounted() {
    this.player.on('playing', () => {
      gsap
        .to('#buffering', {
          autoAlpha: 0,
          duration: 0.3,
          delay: 1,
          callbackScope: this,
        })
        .then(() => {
          if (this.firstTimeVideoPlayed == true) {
          if (this.$root.$data.visible == false) {
            gsap.set('#circularRevealWrapper', { visibility: 'initial', width: '100%' })
            gsap
              .fromTo(
                '#cover',
                { attr: { r: 0 } },
                {
                  attr: { r: this.r },
                }
              )
              .then(() => {
                gsap.to(
                  '#circularRevealWrapper',
                  {
                    autoAlpha: 0,
                    callbackScope: this,
                    onComplete: () => {
                      gsap.set('#circularRevealWrapper', { display: 'none' })
                      this.$emit('showTopNavHeader')
                      this.$root.initialLoad = false
                      this.$root.$data.visible = true
                      this.firstTimeVideoPlayed = false
                    },
                  }
                )
              })
          } else {
            gsap.to('#circularRevealWrapper', {
              display: 'none',
              callbackScope: this,
              onComplete: () => {
                this.$emit('showTopNavHeader')
              },
            })
          }
          }
        })
      this.player.muted = true
      this.player.volume = 0
      var playPromise = this.player.play()
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            console.log('Playing summit Video')
          })
          .catch((error) => {
            // Autoplay was prevented.
            // Show a "Play" button so that user can start playback.
            console.log(`Could not play summit Video: ${error}`)
          })
      }
    })
    this.figureMask()
  },
  unmounted() {
    this.player.destroy()
  },
  activated() {
    this.player.play()
  },
  computed: {
    player() {
      // Return the vue-plyr element
      return this.$refs.plyr.player
    },
  },
  deactivated() {
    this.player.pause()
  },
  emits: ['playing', 'showTopNavHeader'],
}
</script>
<style lang="scss">
#main-video-wrapper {
  width: 100% !important;
  height: 100vh !important;
  max-height: 100vh !important;
}
video[poster] {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}

.plyr__poster {
  background-size: cover !important;
  object-fit: cover;
  object-position: center center;
}
</style>
