<template>
  <div id="presents-section" class="presents-scroll-trigger">
    <div class="slide-container">
      <div class="slide" data-slide="1">
        <div id="presents-background">
          <vue-plyr
            ref="presentsBackground"
            loading="eager"
            :options="presentsBackgroundOptions">
            <video
              id="player"
              muted
              playsinline
              crossorigin
              autoplay
              :data-poster="presentsBackgroundPic.src"
              preload="metadata">
              <source
                src="@/assets/video/mis-presents-background.webm"
                type="video/webm" />
              <source
                src="@/assets/video/mis-presents-background.mp4"
                type="video/mp4" />
            </video>
          </vue-plyr>
        </div>
        <div id="presents-banner">
          <img id="presents-logo" src="@/assets/images/presents-logo.svg" alt="The Make It So Presents Logo" />
          <div id="presents-text">PRESENTS</div>
          <div id="presents-description">
            <div class="list">IPAD</div>
            <div class="list">INTERACTIVE</div>
            <div class="list">EDUCATIONAL</div>
            <div class="list">VOTING</div>
            <div class="list">SOFTWARE</div>
          </div>
        </div>
      </div>
      <div class="slide" data-slide="2">
        <div id="presents-blurb">
          <div id="blurb-text">
            What follows is a demonstration of <b>Make It So Presents</b> a
            custom moderated educational platform that can aid in the delivery
            of knowledge to your audience.
          </div>
        </div>
      </div>
      <div class="slide" data-slide="3">
        <div id="quiz-demo-grid-container">
          <div id="mis-presents-mainscreen-wrapper">
            <div id="mis-presents-mainscreen">
              <div class="screen-description">Main Screen</div>
              <vue-plyr
                ref="presentsMainscreen"
                loading="eager"
                controls
                :options="presentsMainscreenOptions">
                <video
                  style="--plyr-color-main: #7b3c52"
                  muted
                  playsinline
                  crossorigin
                  :data-poster="mainscreenPic.src"
                  preload="metadata">
                  <source
                    src="@/assets/video/mis-presents-mainscreen.webm"
                    type="video/webm" />
                  <source
                    src="@/assets/video/mis-presents-mainscreen.mp4"
                    type="video/mp4" />
                </video>
              </vue-plyr>
            </div>
          </div>
          <div id="mis-presents-ipad-wrapper">
            <div id="mis-presents-ipad">
              <div class="screen-description">Participants iPad Screen</div>
              <vue-plyr
                ref="presentsIpad"
                loading="eager"
                controls
                :options="presentsIpadOptions">
                <video
                  style="--plyr-color-main: #7b3c52"
                  muted
                  playsinline
                  crossorigin
                  :data-poster="ipadPic.src"
                  preload="metadata">
                  <source
                    src="@/assets/video/mis-presents-ipad.webm"
                    type="video/webm" />
                  <source
                    src="@/assets/video/mis-presents-ipad.mp4"
                    type="video/mp4" />
                </video>
              </vue-plyr>
            </div>
          </div>
        </div>
      </div>
      <div class="slide" data-slide="4">
        <div id="presents-blurb2">
          <div id="blurb2-container">
            <div class="intro">
              <p>
                <span
                  >Our interactive platform can be specially tailored, given a
                  certain time period, to the specifications of a clients
                  requirements. Currently our system enables the
                  following:</span
                >
              </p>
            </div>
            <div class="features">
              <div id="list">
                <ul>
                  <li>
                    Secured offline functionality between devices with no need
                    for internet.
                  </li>
                  <li>
                    Live refreshment ordering built into the activity as session
                    progresses to help relieve waiting times during busy
                    periods.
                  </li>
                  <li>
                    True/False question options with up to eight options
                    displayed.
                  </li>
                  <li>
                    Explanation summary of correct option displayed for
                    reaffirming key messaging.
                  </li>
                  <li>
                    Opinion sliders that allow you to receive a delegates
                    strength of feeling on a particular subject.
                  </li>
                  <li>
                    Feedback questions which can be entered live and stored on
                    the iPad devices.
                  </li>
                  <li>
                    Any audio/visual presentation included during the session as
                    desired.
                  </li>
                  <li>
                    Pie Chart and Bar Chart Representation of given responses
                    live during session for instant visual feedback of group
                    responses.
                  </li>
                  <li>
                    Secured offline results that can be viewed as sessions
                    progress.
                  </li>
                  <li>End of event reports with results and statistics.</li>
                </ul>
              </div>
            </div>
            <div class="logo">
              <img src="~@/assets/images/presents-logo.svg" alt="The Make It So Presents Logo" />
            </div>
          </div>
        </div>
      </div>
      <div class="slide" data-slide="5">
        <div id="barcelona-video-wrapper">
          <div id="barcelona-video">
            <vue-plyr
              ref="presentsBarcelona"
              @pause="this.barcelonaVideoPaused()"
              @playing="barcelonaVideoPlaying"
              :emit="['timeupdate']"
              loading="eager"
              controls
              :options="presentsBarcelonaOptions">
              <video
                style="--plyr-color-main: #7b3c52"
                muted
                playsinline
                crossorigin
                :data-poster="barcelonaBackgroundPic.src">
                <source
                  src="@/assets/video/mis-presents-barcelona.webm"
                  type="video/webm" />
                <source
                  src="@/assets/video/mis-presents-barcelona.mp4"
                  type="video/mp4" />
              </video>
            </vue-plyr>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { gsap, ScrollTrigger } from 'gsap/all'

export default {
  data() {
    return {
      presentsBackgroundPic: require('@/assets/video/mis-presents-background.png?placeholder=false&sizes[]=1000,sizes[]=2000,sizes[]=3000'),
      barcelonaBackgroundPic: require('@/assets/video/mis-presents-barcelona.png?placeholder=false&sizes[]=1000,sizes[]=2000,sizes[]=3000"'),
      mainscreenPic: require('@/assets/video/mis-presents-mainscreen.png?placeholder=false&sizes[]=1000,sizes[]=2000,sizes[]=3000'),
      ipadPic: require('@/assets/video/mis-presents-ipad.png?placeholder=false&sizes[]=1000,sizes[]=2000,sizes[]=3000'),
      presentsMainscreenOptions: {
        captions: {
          active: false,
        },
        autopause: false,
        autoplay: true,
        loop: {
          active: false,
        },
        blankVideo: require('@/assets/video/blank.mp4'),
        muted: true,
        debug: false,
        clickToPlay: false,
        fullscreen: {
          enabled: false,
          iosNative: false,
        },
        events: ['playing', 'pause', 'ready'],
        displayDuration: false,
        volume: 0,
        quality: {
          default: 1080,
          forced: false,
          options: [4320, 2880, 2160, 1440, 1080, 720, 576, 480, 360, 240],
        },
        controls: [],
        disableContextMenu: true,
      },
      presentsIpadOptions: {
        captions: {
          active: false,
        },
        autopause: false,
        autoplay: true,
        blankVideo: require('@/assets/video/blank.mp4'),
        loop: {
          active: false,
        },
        muted: true,
        debug: false,
        clickToPlay: false,
        fullscreen: {
          enabled: false,
          iosNative: false,
        },
        events: ['playing', 'pause', 'ready'],
        displayDuration: false,
        volume: 0,
        quality: {
          default: 1080,
          forced: false,
          options: [4320, 2880, 2160, 1440, 1080, 720, 576, 480, 360, 240],
        },
        controls: [],
        disableContextMenu: true,
      },
      presentsBackgroundOptions: {
        captions: {
          active: false,
        },
        autopause: false,
        autoplay: true,
        loop: {
          active: true,
        },
        muted: true,
        debug: false,
        blankVideo: require('@/assets/video/blank.mp4'),
        clickToPlay: true,
        fullscreen: {
          enabled: false,
          iosNative: false,
        },
        events: [],
        displayDuration: false,
        volume: 0,
        quality: {
          default: 1080,
          forced: false,
          options: [4320, 2880, 2160, 1440, 1080, 720, 576, 480, 360, 240],
        },
        controls: [],
        disableContextMenu: true,
      },
      presentsBarcelonaOptions: {
        captions: {
          active: false,
        },
        autopause: false,
        autoplay: true,
        blankVideo: require('@/assets/video/blank.mp4'),
        loop: {
          active: true,
        },
        muted: true,
        debug: false,
        clickToPlay: true,
        fullscreen: {
          enabled: true,
          fallback: true,
          iosNative: true,
        },
        events: ['playing', 'pause', 'ready'],
        displayDuration: false,
        volume: 0,
        quality: {
          default: 1080,
          forced: false,
          options: [1080, 720, 576, 480, 360, 240],
        },
        controls: ['play-large', 'mute', 'pause', 'airplay', 'fullscreen'],
        disableContextMenu: true,
      },
      presentsTL: null,
      userInteractedWithPage: false,
    }
  },
  methods: {
    hidePlayButton: function () {
      let playButton = document.querySelector('#barcelona-video > div > button')
      playButton.style.opacity = 0
      playButton.style.display = 'none'
      gsap.set('#barcelona-video-wrapper', { zIndex: '1' })
    },
    showPlayButton: function () {
      gsap.set('#barcelona-video-wrapper', { zIndex: '6' })
      let playButton = document.querySelector('#barcelona-video > div > button')
      playButton.style.display = 'inherit'
      gsap.to(playButton, { opacity: 1, duration: 0.2 })
    },
    barcelonaVideoPaused: function () {
      this.$refs.presentsBarcelona.player.muted = true
      this.$refs.presentsBarcelona.player.volume = 0
    },
    barcelonaVideoPlaying: function () {
      this.$refs.presentsBarcelona.player.muted = false
      this.$refs.presentsBarcelona.player.volume = 1
    },
    playBarcelonaVideo: function () {
      this.$refs.presentsBarcelona.player.muted = false
      this.$refs.presentsBarcelona.player.volume = 1
      try {
        var playPromise = this.$refs.presentsBarcelona.player.play()
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              console.log('Playing Barcelona Video')
              this.$refs.presentsBarcelona.player.muted = false
              this.$refs.presentsBarcelona.player.volume = 1
              this.userInteractedWithPage = true
            })
            .catch((error) => {
              // Autoplay was prevented.
              // Show a "Play" button so that user can start playback.
              console.log(`Could not play Barcelona Video: ${error}`)
            })
        }
      } catch (error) {
        console.log(`Could not play Barcelona Video: ${error}`)
      }
    },
    playMainscreenVideo: function () {
      this.$refs.presentsMainscreen.player.muted = true
      this.$refs.presentsMainscreen.player.volume = 0
      var playPromise = this.$refs.presentsMainscreen.player.play()
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            console.log('Playing Mainscreen Video')
            this.$refs.presentsMainscreen.player.muted = true
            this.$refs.presentsMainscreen.player.volume = 0
          })
          .catch((error) => {
            // Autoplay was prevented.
            // Show a "Play" button so that user can start playback.
            console.log(`Could not play Mainscreen Video: ${error}`)
          })
      }
    },
    playIpadVideo: function () {
      this.$refs.presentsIpad.player.muted = true
      this.$refs.presentsIpad.player.volume = 0
      var playPromise = this.$refs.presentsIpad.player.play()
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            console.log('Playing iPad Video')
            this.$refs.presentsIpad.player.muted = true
            this.$refs.presentsIpad.player.volume = 0
          })
          .catch((error) => {
            // Autoplay was prevented.
            // Show a "Play" button so that user can start playback.
            console.log(`Could not play iPad Video: ${error}`)
          })
      }
    },
    resetMainscreenVideo: function () {
      this.$refs.presentsMainscreen.player.stop()
    },
    resetIpadVideo: function () {
      this.$refs.presentsIpad.player.stop()
    },
  },
  beforeUnmount() {
    this.presentsTL.kill()
    this.$refs.presentsIpad.player.destroy()
    this.$refs.presentsMainscreen.player.destroy()
    this.$refs.presentsBarcelona.player.destroy()
    this.$refs.presentsBackground.player.destroy()
  },
  mounted() {
    this.presentsTL = gsap
      .timeline({
        scrollTrigger: {
          trigger: '.presents-scroll-trigger',
          id: 'MISPresentsTrigger',
          pin: true,
          pinSpacing: 'margin',
          scrub: 0.3,
          markers: false,
          anticipatePin: 1,
          snap: {
            snapTo: 'labelsDirectional',
            ease: 'power3', // the ease of the snap animation ("power3" by default)
          },
          start: 'top top',
          end: '+=700%',
          toggleActions: 'play pause resume reverse',
        },
      })
      // .set('#mailer *', { autoAlpha: 0 })
      // First slide
      .from(
        "[data-slide='1'] #presents-logo",
        {
          opacity: 0,
          duration: 1,
          scale: 4.5,
          onStart: () => {
            this.$nextTick(() => {
              this.$refs.presentsBackground.player.muted = true
              this.$refs.presentsBackground.player.volume = 0
              var playPromise = this.$refs.presentsBackground.player.play()
              if (playPromise !== undefined) {
                playPromise
                  .then(() => {
                    console.log('Playing Presents Background Video')
                  })
                  .catch((error) => {
                    // Autoplay was prevented.
                    // Show a "Play" button so that user can start playback.
                    console.log(
                      `Could not play Presents Background Video: ${error}`
                    )
                  })
              }
            })
          },
        },

        '+=1'
      )
      .from(
        "[data-slide='1'] #presents-text",
        {
          opacity: 0,
          duration: 1,
          scale: 4.5,
        },

        '+=1'
      )
      .from(
        "[data-slide='1'] #presents-description",
        {
          opacity: 0,
          duration: 1,
          x: '-30vw',
        },

        '+=1'
      )
      .addLabel('slide1', '>')
      .to(
        "[data-slide='1'] #presents-logo",
        {
          opacity: 0,
          duration: 1,
          scale: 4.5,
        },

        '+=10'
      )
      .to("[data-slide='1'] #presents-text", {
        opacity: 0,
        duration: 1,
        scale: 4.5,
      })
      .to("[data-slide='1'] #presents-description", {
        opacity: 0,
        duration: 1,
        x: '-30vw',
      })
      // Second Slide
      .from(
        "[data-slide='2'] #presents-blurb",
        {
          opacity: 0,
          duration: 1,
          scale: 4.5,
        },

        '+=1'
      )
      .from(
        "[data-slide='2'] #blurb-text",
        {
          opacity: 0,
          duration: 1,
          scale: 4.5,
        },

        '+=1'
      )
      .addLabel('slide2', '>')
      .to(
        "[data-slide='2'] #presents-blurb",
        {
          opacity: 0,
          duration: 1,
          scale: 4.5,
        },

        '+=10'
      )
      .to(
        "[data-slide='2'] #blurb-text",
        {
          opacity: 0,
          duration: 1,
        },

        '+=10'
      )
      // Third slide
      .from(
        "[data-slide='3'] #mis-presents-mainscreen, [data-slide='3'] #mis-presents-ipad",
        {
          opacity: 0,
          duration: 1,
          callbackScope: this,
          onComplete: function () {
            this.playMainscreenVideo()
            this.playIpadVideo()
          },
          onReverseComplete: function () {
            this.resetMainscreenVideo()
            this.resetIpadVideo()
            gsap.to("[data-slide='3'] #mis-presents-mainscreen", { opacity: 0 })
          },
        },

        '+=1'
      )
      .addLabel('slide3', '>')
      .to(
        "[data-slide='3'] #mis-presents-mainscreen, [data-slide='3'] #mis-presents-ipad",
        {
          opacity: 0,
          duration: 1,
          callbackScope: this,
          onReverseComplete: function () {
            this.playMainscreenVideo()
            this.playIpadVideo()
          },
          onComplete: function () {
            this.resetMainscreenVideo()
            this.resetIpadVideo()
          },
        },
        '+=10'
      )
      // Fourth Slide
      .from(
        "[data-slide='4'] > #presents-blurb2",
        {
          opacity: 0,
          duration: 1,
        },

        '+=1'
      )
      .from('#blurb2-container > div.logo', {
        x: '-40vw',
        opacity: 0,
        scale: 4.5,
      })
      .from('#blurb2-container > .intro', {
        opacity: 0,
        duration: 1,
        scale: 4.5,
      })
      .from(' #blurb2-container > .features', {
        opacity: 0,
        duration: 1,
        y: '-10vh',
      })
      .from(
        '#blurb2-container > .features ul li',
        {
          opacity: 0,
          duration: 2,
          stagger: 0.1,
          y: '-3vh',
        },
        '+=2'
      )
      .addLabel('slide4', '>')
      .to(
        "[data-slide='4'] #presents-blurb2",
        {
          duration: 1,
        },

        '+=10'
      )
      .to('#blurb2-container > .logo', {
        x: '40vw',
        opacity: 0,
        scale: 4.5,
      })
      .to('#blurb2-container > .intro', {
        opacity: 0,
        duration: 1,
        scale: 4.5,
      })
      .to('#blurb2-container > .features', {
        opacity: 0,
        duration: 1,
        y: '10vh',
      })
      .to(
        '#blurb2-container > .features ul li',
        {
          opacity: 0,
          duration: 2,
          stagger: 0.1,
          y: '10vh',
        },
        '+=2'
      )
      // Fifth Slide
      .from("[data-slide='5'] #barcelona-video", {
        opacity: 0,
        duration: 1,
        callbackScope: this,
        onComplete: function () {
          if (!this.userInteractedWithPage) {
            this.showPlayButton()
          }
          this.playBarcelonaVideo()
        },
        onReverseComplete: function () {
          this.$refs.presentsBarcelona.player.stop()
        },
      })
      .addLabel('slide5', '>')
      // .set('#mailer *', { autoAlpha: 0 })
      .to(
        "[data-slide='5'] #barcelona-video",
        {
          opacity: 0,
          callbackScope: this,
          onReverseComplete: function () {
            // gsap.to('#mailer *', {
            //   autoAlpha: 0,
            //   x: -25,
            //   y: -25,
            //   stagger: 0.01,
            // })
            gsap.to('.scroll-down', { autoAlpha: 1, duration: 0.3 })
            this.playBarcelonaVideo()
          },
          onComplete: function () {
            this.$refs.presentsBarcelona.player.stop()
            // Reveal contact form
            // gsap.fromTo(
            //   '#mailer *',
            //   { autoAlpha: 0, x: -25, y: -25 },
            //   {
            //     autoAlpha: 1,
            //     x: 0,
            //     y: 0,
            //     stagger: 0.01,
            //     immediateRender: false,
            //     onComplete: () => {
            //       gsap.to('.scroll-down', { autoAlpha: 0, duration: 0.3 })
            //     },
            //   }
            // )
          },
        },
        '+=10'
      )
  },
  activated() {
    ScrollTrigger.getById('MISPresentsTrigger').enable()
    ScrollTrigger.refresh(true)
  },
  deactivated() {
    ScrollTrigger.getById('MISPresentsTrigger').disable()
  },
}
</script>
<style lang="scss" scoped>
.slide-container {
  display: grid;
}

.slide {
  grid-row: 1;
  grid-column: 1;
  background-color: black;
}

#presents-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

#presents-background {
  display: inline-block;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.title {
  font-size: 6vw;
  margin-bottom: 3rem;
  color: #ffffff;
  text-align: center;
}

.slide {
  width: 100%;
  position: relative;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: white;
  overflow: hidden;
}

#presents-banner {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: white;
}

#presents-logo {
  width: 10vmin;
  height: 10vmin;
  z-index: 1;
  filter: drop-shadow(0px 0px 2vmin rgb(0 0 0 / 0.2));
}

#presents-text {
  font-size: 6vmin;
  padding-left: 3vmin;
  padding-right: 3vmin;
  z-index: 1;
  filter: drop-shadow(0px 0px 0.3vmin rgb(0 0 0 / 0.7));
}

#presents-description {
  font-size: 2.2vmin;
  border-left: 0.6vmin white solid !important;
  padding: 0px;
  margin: 0px;
  padding-left: 3vmin;
  letter-spacing: 0.3vmin;
  filter: drop-shadow(0px 0px 0.3vmin rgb(0 0 0 / 0.7));
  z-index: 1;
}

#presents-blurb {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: white;
  z-index: 2;
}

#blurb-text {
  font-size: 3.4vmin;
  filter: drop-shadow(0px 0px 0.3vmin rgb(0 0 0 / 0.7));
  z-index: 1;
  max-width: 1440px;
  text-align: center;
  margin-left: 20vmin;
  margin-right: 20vmin;
}
#presents-blurb2 {
  width: 100%;
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: white;
  z-index: 2;
}

#blurb2-container {
  position: relative;
  max-width: 1440px;
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: min-content min-content;
  gap: 0em 2vmax;
  grid-template-areas:
    'logo intro'
    '. features';
  justify-content: center;
  margin: 1em;

  @media #{$mq-tiny-small} {
    margin: 1.5rem;
  }
  @media #{$mq-tiny} {
    margin: 1.5rem;
  }
  @media #{$mq-small} {
    margin: 7rem;
  }
  @media #{$mq-medium} {
    margin: 5.1rem;
  }

  @media #{$mq-medium} and (orientation: portrait) {
    margin: 5.1rem;
  }
  @media #{$mq-large} {
    margin: 5.6rem;
  }
  @media #{$mq-xlarge} {
    margin: 5.7rem;
  }
  @media #{$mq-xxlarge} {
    margin: 5.4rem;
  }
}

#blurb2-container .intro {
  grid-area: intro;
  filter: drop-shadow(0px 0px 0.3vmin rgb(0 0 0 / 0.7));
  @media #{$mq-tiny} {
    font-size: 3.4vmin;
  }
  @media #{$mq-tiny-small} {
    font-size: 2.7vmin;
  }

  @media #{$mq-small} {
    font-size: 2.5vmin;
  }
  @media #{$mq-medium} {
    font-size: 2.2vmin;
  }
  @media #{$mq-large} {
    font-size: 2.6vmin;
  }
}

#blurb2-container .features {
  filter: drop-shadow(0px 0px 0.3vmin rgb(0 0 0 / 0.7));
  grid-area: features;
  grid-column: 1 / -1;
  @media #{$mq-tiny} {
    ul {
      padding: 0px;
    }
    font-size: 3.2vmin;
  }

  @media #{$mq-tiny-small} {
    font-size: 2.5vmin;
  }

  @media #{$mq-small} {
    font-size: 2.2vmin;
  }
  @media #{$mq-medium} {
    font-size: 2vmin;
  }
  @media #{$mq-large} {
    font-size: 2vmin;
  }
}

#blurb2-container .features ul li {
  margin-bottom: 1.2vmin;
}

#blurb2-container .logo {
  filter: drop-shadow(0px 0px 2vmin rgb(0 0 0 / 0.5));
  grid-area: logo;
  display: flex;
  justify-content: center;
  align-items: center;
}

#blurb2-container .logo img {
  width: 16.5vmin;
  height: 16.5vmin;

  @media #{$mq-tiny-small} {
    width: 16.5vmin;
    height: 16.5vmin;
  }

  @media #{$mq-tiny-small} {
    width: 16.5vmin;
    height: 16.5vmin;
  }
  @media #{$mq-tiny} {
    width: 19.5vmin;
    height: 19.5vmin;
  }
  @media #{$mq-small} {
    width: 12.5vmin;
    height: 12.5vmin;
  }
  @media #{$mq-medium} {
    width: 12.5vmin;
    height: 12.5vmin;
  }
  @media #{$mq-large} {
    width: 15.5vmin;
    height: 15.5vmin;
  }
}

#list > ul {
  list-style-type: square;
}

#list > ul > li {
  padding: 0px;
  margin-left: 35px;
}

// MIS Presents demo slide
#presents-section > div > div:nth-child(3) {
  display: inline-block;
  margin: 0 auto;
}

#quiz-demo-grid-container {
  display: grid;
  width: 90%;
  margin: 0 auto;
  height: 100%;
  justify-items: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));

  @media #{$mq-tiny} {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  @media #{$mq-small} {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  @media #{$mq-medium} {
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
  }

  @media all and (orientation: landscape) {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
  }
  @media all and (width: 1024px) and (height: 1366px) and (orientation: portrait) {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  /* change this value with media queries to move items one on top of the other but also keep on screen */
}

.screen-description {
  font-size: 1.2vmax;
  padding-bottom: 1vmin;
}
#mis-presents-mainscreen-wrapper {
  justify-self: end;
  max-width: 720px;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 50vh;
  top: 0vh;

  @media #{$mq-tiny-small} {
    width: 100%;
    height: 50vh;
    top: 5vh;
    justify-self: revert;
  }
  @media #{$mq-tiny} {
    width: 100%;
    height: 50vh;
    top: 5vh;
    justify-self: revert;
  }

  @media #{$mq-small} {
    width: 100%;
    height: 50vh;
    top: 5vh;
    justify-self: revert;
  }
  @media #{$mq-medium} {
    height: 100vh;
    top: 0vh;
  }
  @media #{$mq-large} {
    height: 100vh;
    top: 0vh;
    max-width: 1440px;
  }
  @media all and (orientation: landscape) {
    height: 100vh;
    top: 0;
  }
  @media all and (width: 1024px) and (height: 1366px) and (orientation: portrait) {
    height: 50vh;
  }
}

#mis-presents-mainscreen {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  aspect-ratio: 16/8.2;
}

#mis-presents-ipad-wrapper {
  z-index: 1;
  width: 100vw;
  max-width: 720px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  justify-self: start;
  top: 0;

  @media #{$mq-tiny-small} {
    width: 100%;
    height: 50vh;
    top: -5vh;
    justify-self: revert;
  }
  @media #{$mq-tiny} {
    width: 100%;
    height: 50vh;
    top: -5vh;
    justify-self: revert;
  }

  @media #{$mq-small} {
    width: 100%;
    height: 50vh;
    top: -5vh;
    justify-self: revert;
  }
  @media #{$mq-medium} {
    width: 100%;
    height: 100vh;
    top: 0vh;
  }
  @media #{$mq-large} {
    width: 100%;
    height: 100vh;
    top: 0vh;
    max-width: 1440px;
  }
  @media all and (orientation: landscape) {
    width: 100%;
    height: 100vh;
    top: 0;
  }
  @media all and (width: 1024px) and (height: 1366px) and (orientation: portrait) {
    height: 50vh;
    top: -8vh;
  }

  @media all and (width: 428px) and (height: 926px) and (orientation: portrait) {
    height: 50vh;
    top: -8vh;
  }
}

#mis-presents-ipad {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  aspect-ratio: 0.67/0.5;
  max-height: 40vmax;
  margin: 0 auto;
}

#barcelona-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#barcelona-video-wrapper {
  /* This is how we properly keep aspect ratio of video/images whilst setting margins and a max-width! */
  position: relative;
  display: block;
  width: 95%;
  max-width: 95vmin;
  height: auto;
  max-height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  z-index: 50;
  @media #{$mq-small} {
    max-width: 95vmin;
  }
  @media #{$mq-medium} {
    max-width: 90vmin;
  }
  @media #{$mq-large} {
    max-width: 110vmin;
  }
  @media #{$mq-xxlarge} {
    max-width: 120vmin;
  }
}

#barcelona-video-wrapper::before {
  padding-top: 57.25%;
  display: block;
  content: '';
}
</style>
