<template>
  <div>
    <section id="home" class="panel">
      <div id="circularRevealWrapper">
        <svg
          id="circularReveal"
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%">
          <defs>
            <mask id="mask" x="0" y="0" width="100%" height="100%">
              <rect id="goAway" x="0" y="0" width="100%" height="100%" />
              <circle id="cover" r="0" fill="black" cx="50%" cy="50%" />
            </mask>
          </defs>
          <rect x="0" y="0" width="100%" height="100%" />
        </svg>
      </div>
      <MainVideo @showTopNavHeader="showTopNavHeader" ref="plyrVideo" />
      <div id="centerTextWrapper">
        <div id="centertext">
          <span class="entertain">ENTERTAIN</span>
          <span class="blurredPunctuation">, </span>
          <span class="educate">EDUCATE</span>
          <span class="blurredPunctuation"> & </span>
          <span class="engage">ENGAGE</span>
        </div>
        <div id="block-statement-wrapper">
          <div id="block-statement"></div>
        </div>
      </div>
    </section>
    <MISPresents />
  </div>
</template>

<script>
import MainVideo from '@/components/Home Page/MainVideo.vue'
import MISPresents from '@/components/Home Page/MISPresents.vue'

import { gsap } from 'gsap/all'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { CSSRulePlugin } from 'gsap/CSSRulePlugin'
import { TextPlugin } from 'gsap/TextPlugin'
import '../assets/scripts/blur'

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, CSSRulePlugin, TextPlugin)

export default {
  name: 'HomeItem',
  emits: ['showTopNavHeader'],
  components: { MainVideo, MISPresents },
  methods: {
    showTopNavHeader() {
      // Start the centralTextAnimationTL
      if (this.centralTextAnimationTL) {
        this.centralTextAnimationTL.play()
      }
      // Emit event to App.
      this.$emit('showTopNavHeader')
    },
  },
  data() {
    return {
      centralTextAnimationTL: null,
      textDisplayTime: 9,
      panelScrollTriggers: [],
    }
  },
  mounted() {
    this.centralTextAnimationTL = gsap
      .timeline({
        id: 'centralTextAnimationTL',
        repeat: -1,
        paused: true,
      })
      .set('#block-statement', { autoAlpha: 0 })
      .to('#centertext', {
        autoAlpha: 1,
        duration: 0.5,
      })
      .fromTo(
        ['.entertain', '.educate', '.engage'],
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          duration: 1,
          blur: 1,
          stagger: 0.5,
          delay: 1.5,
        }
      )
      .fromTo(
        '.blurredPunctuation',
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          blur: 1,
          opacity: 1,
          duration: 0.5,
        },
        '-=0.5'
      )
      .to(
        '.entertain',
        {
          fontWeight: 'normal',
          duration: 0.9,
          blur: 0,
          delay: 0.3,
          onStart: () => {
            gsap.set('#block-statement', {
              text: 'We create professional energised events using our many years of experience in the entertainment industry.',
            })
          },
          onComplete: () => {
            gsap.to('#block-statement', { autoAlpha: 1, duration: '0.9' })
          },
        },
        '>'
      )
      .to(
        '.entertain',
        {
          fontWeight: 100,
          duration: 0.9,
          blur: 1,
          delay: this.textDisplayTime,
          onStart: () => {
            gsap.to('#block-statement', { autoAlpha: 0, duration: '0.9' })
          },
        },
        '>'
      )
      .to(
        '.educate',
        {
          fontWeight: 'normal',
          duration: 0.9,
          blur: 0,
          onStart: () => {
            gsap.set('#block-statement', {
              text: 'We deliver a platform for re-affirming key messaging and promoting knowledge of new products or services using innovative custom built software.',
            })
          },
          onComplete: () => {
            gsap.to('#block-statement', { autoAlpha: 1, duration: '0.9' })
          },
        },
        '>'
      )
      .to(
        '.educate',
        {
          fontWeight: 100,
          duration: 0.9,
          delay: this.textDisplayTime,
          blur: 1,
          onStart: () => {
            gsap.to('#block-statement', { autoAlpha: 0, duration: '0.9' })
          },
        },
        '>'
      )
      .to(
        '.engage',
        {
          fontWeight: 'normal',
          duration: 0.9,
          blur: 0,
          onStart: () => {
            gsap.set('#block-statement', {
              text: 'We make sure our audience is given our full attention and using infectious energy we deliver a positive highly memorable experience.',
            })
          },
          onComplete: () => {
            gsap.to('#block-statement', { autoAlpha: 1, duration: '0.9' })
          },
        },
        '>'
      )
      .to(
        '.engage',
        {
          fontWeight: 100,
          duration: 0.9,
          delay: this.textDisplayTime,
          blur: 1,
          onStart: () => {
            gsap.to('#block-statement', { autoAlpha: 0, duration: '0.9' })
          },
        },
        '>'
      )
      .to(
        ['.entertain', '.educate', '.engage', '.blurredPunctuation'],
        {
          duration: 2,
          ease: 'none',
          opacity: 1,
          blur: 0,
          fontWeight: 'normal',
        },
        '>'
      )
      .to(
        ['.entertain', '.educate', '.engage', '.blurredPunctuation'],
        {
          duration: 2,
          delay: 3,
          ease: 'none',
          opacity: 0,
          blur: 0,
        },
        '>'
      )
  },
  unmounted() {
    this.centralTextAnimationTL.kill()
  },
  deactivated() {
    this.centralTextAnimationTL.pause()
  },
  activated() {
    this.centralTextAnimationTL.play()
    if (this.$root.initialLoad === false) {
      this.showTopNavHeader()
    }
  },
}
</script>
<style lang="scss">
#centertext {
  /* transform: translateX(-50%) translateY(-50%); */
  z-index: 2;
  font-size: 8vmin;
  color: whitesmoke;
  font-weight: 100;
  visibility: hidden;
  filter: drop-shadow(0px 0px 0.3vmin rgb(0 0 0 / 1));
  font-family: 'Bebas Neue Pro';
  @media #{$mq-tiny} {
    font-size: 9.5vmin;
  }
}

#centertext span {
  filter: blur(1px);
  text-rendering: optimizeLegibility;
}
#gradient {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.558);
  background-blend-mode: soft-light;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
  pointer-events: none;
  cursor: not-allowed;
}

#circularReveal {
  position: fixed;
}

#centerTextWrapper {
  position: absolute;
  width: 100%;
  top: 0;
  justify-content: space-around;
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  @media #{$mq-tiny} {
    top: -7vh;
  }
}

#block-statement-wrapper {
  position: absolute;
  top: 53vh;
  width: 100%;
  z-index: 3;
  height: 40vh;
  max-width: 150vmin;
}

#block-statement {
  position: absolute;
  color: rgb(234, 234, 234);
  text-align: center;
  font-size: 2.5vmin;
  font-style: italic;
  filter: drop-shadow(0px 0px 0.3vmin rgb(0 0 0 / 1));
  top: 50%;
  left: 50%;
  width: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  @media #{$mq-tiny} {
    top: 39%;
    width: 70%;
    font-size: 4.3vmin;
  }
}

#circularRevealWrapper {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  transform: translateZ(1400px);
}

svg mask rect {
  fill: rgba(255, 255, 255, 1);
}
svg > rect {
  fill: $makeitsopurple;
  -webkit-mask: url(#mask);
  mask: url(#mask);
}
</style>
